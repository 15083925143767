import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';
const isPartnerPlatform = constants.platform === 'partner';

// components
const DesktopMenuBar = dynamic(
	() => import('@/components/shared/menuBar/partials/desktopMenuBar'),
	{ ssr: true }
);
const MobileMenuBar = dynamic(
	() => import('@/components/shared/menuBar/partials/mobileMenuBar'),
	{ ssr: true }
);

export default function MenuBar(props) {
	const isDesktop = useSelector((state) => state.common.isDesktop);

	if (isDesktop) {
		return (
			<DesktopMenuBar
				{...props}
				logo={{
					...props.logo,
					height: isPartnerPlatform ? '72px' : '62px',
					width: isPartnerPlatform ? '232px' : '200px',
				}}
			/>
		);
	}

	return (
		<MobileMenuBar
			{...props}
			logo={{
				...props.logo,
				height: isPartnerPlatform ? '56px' : '46px',
				width: isPartnerPlatform ? '180px' : '150px',
			}}
		/>
	);
}
